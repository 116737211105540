import React from 'react'
import bina from "../css/bina.jpg"

const Foot = () => {
  return (
    <div  id='iletisim' className='okul-ana container d-flex  flex-wrap'>
        <div >
        <img className='okul' style={{width:"650px"}} src={bina} alt="" srcset="" />
        </div>
        <div>
        <iframe className='okul-harita' src="https://www.google.com/maps/d/embed?mid=1eofrX5DttviD5KCB_9n2R8PSgyUNqio&ehbc=2E312F&noprof=1" width="550" height="500"></iframe>
        </div>
        <div>
        <p className='okul-bilgi' style={{background:"#8ecae6",display:"flex" ,justifyContent:"center",alignItems:"center",width:"300px"}}>{`Email:fencagiegi@gmail.com

Tel:+90 506 544 19 23

Adres:Cumhuriyet mahallesi Mithat paşa caddesi 30/21 6.kat 

Çankaya / ANKARA `}</p> 

        </div>


    </div>
    
  )
}

export default Foot