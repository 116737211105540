import React from 'react';
import sinif from "../css/sinif.webp";
import "../css/style.css"; 
import ozelders from "../css/ozelders.webp"
import deneme from "../css/deneme.webp"
import kocluk from "../css/kocluk.webp"
import tarama from "../css/TARAMA.webp"

const RackComponent = () => {
  return (
    <div className='container'>
     <h4 className='text-center mt-4 '>Egitim Hizmetlerimiz</h4>
     <div className='d-flex flex-wrap gap-2 egitim'>
      <div className=''>
        <img src={sinif} alt="" className="sinif" width="250px" />
        <h6 className='text-center mt-4 mb-5 text-danger'>12-14 Kişilik Sınıflar</h6>
        <p className='paragraf'>Sınavda öğrencilerin başarılı olabilmesi için öğrencinin konulara tam hakim olması gerekir.
         Bunu da gerçekleştirmek için öncelikle alanında deneyimli uzman öğretmen kadrosuna ve öğrenme sürecini daha sağlıklı gerçekleştirebileceği az öğrencili ideal ortamlara ihtiyaç vardır. 
         Çok kalabalık sınıflarda öğrenci arada kaybolabilirken az öğrencili sınıflarda sürekli öğretmenin gözetim alanı içinde olur öğretmenin öğrenciyi takibi daha sağlıklı yapabilmesi için sınıflardaki öğrenci sayısı ideal ders işleme koşullarında olmalıdır.
          Böylece öğrenci öğretmenin kontrolünde ders içi devamı ve tekrarlarla dersin konusunu daha iyi anlayıp daha başarılı hale gelir
</p>
      </div>
      <div>
      <img src={kocluk} alt="" className="sinif" width="250px" />
      <h6 className='text-center mt-4 mb-5 text-danger'>EĞİTİM KOÇLUĞU</h6>
        <p className='paragraf'>Kurumumuzda eğitim koçluğu ve rehberlik ayrı kişilerle koordineli olarak verilmektedir. Öğrenci için;
        <ul><li>- özel haftalık ders çalışma programı </li>
        <li>-sınav kaygısıyla başa çıkma yolları </li>
        <li>-öğrencinin ödevini takibi</li>
        <li>-verimli ders çalışma yöntemleri </li>
        <li>-öğrencinin hedefine yönelik motivasyonu güçlendirme ve motivasyonunu sürekli diri tutma</li>
        <li>-meslek tanıma</li>
        </ul>gibi 
konularda rehberlik servisimiz ve öğrenci koçumuz sürekli çocuklarla iç içe olup çocukların sıkı bir şekilde takibini yapmaktadır
</p>
      </div>
      
      <div>
      <img src={deneme} alt="" className="sinif" width="250px" />
      <h6 className='text-center mt-4 mb-5 text-danger'>DENEME SINAVLARI</h6>
        <p className='paragraf'>Öğrencilerimize daha faydalı olmak için sınav heyecanını yenebilmek için yıllık 40'tan
         fazla deneme sınavı uygulamaktayız deneme sınavları Türkiye geneli ve kurum içi sınavları olmak üzere 
         gerçekleştirilmektedir farklı yayınevleri ile anlaşarak öğrencinin birçok yayınevinden farklı sorularla
          karşılaşması sağlanmaktadır Böylece öğrencilerimizin sınavı zamanına kadar birçok soru kalıbı görmesi ve 
          tanıması sağlanır 
</p>
      </div>
      <div>
      <img src={ozelders} alt="" className="sinif" width="250px" />
      <h6 className='text-center mt-4 mb-5 text-danger'>ÖZEL DERS</h6>
        <p className='paragraf'>Kurumumuzda sınıf testlerinin yanı sıra her öğrenciye istediği konudan özel ders imkanı sunulmaktadır.
         Alanında uzman öğretmen kadromuzla her öğrencinin konu eksiğini giderip sınava eksiksiz hazırlanması sağlanır</p>
      </div>
      
      <div>
      <img src={tarama} alt="" className="sinif" width="250px" />
      <h6 className='text-center mt-4 mb-5 text-danger'>BİREBİR ETÜT</h6>
        <p className='paragraf'>Kurumumuzda yapılan sınavlarda konu analizlerine göre 
        öğrencinin eksik kaldığı konulardan birebir etüt yapılmaktadır. Bu etüt gerektiğinde öğrencinin isteğine 
        bırakılmaksızın idare tarafından yazılabilir Ayrıca öğrenci zorlandığı konularda ilgili rehberlik servisinden 
        etüt yazdırabilir kurumunuzda her gün 14.45-17.00 aralarında soru çözümleri yapılmaktadır Böylece öğrenci 
        her zaman zorlandığı soruya anında öğretmenine sorabilmektedir.
</p>
      </div>

  
      
     </div>
     <div className='atasoz'>
      <p className='text-center ortasoz'>
'' Geleceğin güvencesi sağlam temellere dayalı bir eğitime, eğitim ise öğretmene dayalıdır `M.Kemal ATATÜRK`  . </p></div>
<div><p className='text-center kucuk'>
'</p></div>

    </div>
  
  );
};

export default RackComponent;
