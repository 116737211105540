import React from 'react'
import "../css/style.css"
import {NavLink} from "react-router-dom"
import Iletisim from './Iletisim'
import Contact from './Contact'


const NavBarLink = () => {
  return (
    <div  className='navs container'>
      <div className="logo">
        <p className='titleweb'>FENÇAĞI EĞİTİM KURUMLARI</p>
      </div>
      <div className="links">
        <ul>
          <li className='linkcontainer'>
          <a href='#home' className="linktitle">Anasayfa</a>
          </li>
         
          <li>
          <a href="#orta" className="linktitle">Egitim Hizmetlerimiz</a>
          </li>
          <li>
          <a href='#iletisim' className="linktitle">iletişim</a>
          </li>
        </ul>
      </div>
      <div className="paths">
        <button className='buttonfirst'>Ögrenci  Sistemi</button>
        <Iletisim/>
        <Contact/>
      </div>

      
      
      
    </div>
  )
}

export default NavBarLink