import {Routes,Route} from "react-router-dom"
import './App.css';
import NavBarLink from "./components/NavBarLink";
import Cursor from "./components/Cursor";
import OrtaKisim from "./components/OrtaKisim";
import Tab1Content from "./components/Tab1Content";
import Foot from "./components/Foot";
import Iletisim from "./components/Iletisim";



function App() {

  return(
    <div id="home">
    <NavBarLink/>
    <Cursor/>
    <OrtaKisim/>
    <Tab1Content/>
    <Foot/>
    
    
   
    
    <Routes>
    <Route path="/faliyetler" element={<OrtaKisim/>}>
    <Route index path="fs" element={<Tab1Content/>}/>
    </Route>
      <Route/>
    </Routes>
    </div>
  )
 
}

export default App;
