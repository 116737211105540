import React from 'react'
import bursluluk from "../css/bursluluk_sınavı_2021_(2).jpg"
import res from "../css/res.jpeg"
import res1 from "../css/banner2.png"
import kurs from "../css/banner3.png"
import gorsel from "../css/gorsel.mp4"
import "../css/style.css"



const Cursor = () => {
  return (
    <div className="container" style={{ display: "flex", alignItems: "flex-start" }}>
    <div style={{ display: "flex", alignItems: "flex-start" }}>
        <img src={kurs} style={{ width: "400px", height: "600px", marginRight: "10px" }} alt="" />
        <img src={res1} style={{ width: "400px", height: "600px", marginRight: "10px" }} alt="" />
    </div>
    <video src={gorsel} width="420" height="600" controls autoPlay />
</div>



    
  )
}

export default Cursor