import React from 'react'
import { Col, Row } from 'react-bootstrap';
import "../css/style.css"
import {BrowserRouter,Routes,Rute} from "react-router-dom"

const OrtaKisim = () => {
  return (
    <div id='orta' className='container'>
        
        <Row className='ortakisim'>
            <Col className='ozel bir'>Özel Dersler</Col>
            <Col className='ozel iki'>12-14 KİŞİLİK SINIFLAR</Col>
            <Col className='ozel uc'>Eğitim Koçluğu</Col>
        </Row>
       
        
    </div>
  )
}

export default OrtaKisim