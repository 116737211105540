import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Example() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Bize Yazın
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>iletişim</Modal.Title>
        </Modal.Header>
        <Modal.Body><iframe src="https://docs.google.com/forms/d/e/1FAIpQLScZB_ougzadyDhW4l3gl7foWrGOPtwhIjke0ZerpDe3nsMEqg/viewform?embedded=true" width="380" height="721" frameborder="0" marginheight="0" marginwidth="0">Yükleniyor…</iframe></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;